import FullScreenCardRight from '../../../views/FullScreenCardRight.vue';

export default {
  name: 'AccountFormsView',

  props: {
    showSubtitle: {
      type: Boolean,
      default: true,
    },
    subTitleText: {
      type: String,
      default: '$vuetify.accountsForm.notif.subTitle',
    },
  },

  components: {
    FullScreenCardRight,
  },

  data() {
    return {
      companyLogoWhite: process.env.VUE_APP_COMPANY_LOGO_W,
    };
  },

  computed: {
    hasPrechoosenPlan() {
      return typeof this.$route.params.plan !== 'undefined';
    },
  },
};
