<template>
  <v-sheet class="layout layout--fullscreen layout--card-right">
    <v-container class="fill-height">
      <v-row>

        <v-col class="d-flex flex-column justify-center"
        cols="12" md="12" lg="8" offset-lg="2" xl="5" offset-xl="0">
          <slot></slot>
        </v-col>

        <v-col class="d-flex flex-column justify-center"
        cols="12" md="12" lg="8" offset-lg="2" xl="5" offset-xl="1">
          <v-card class="form-card mx-auto" :max-width="660" width="100%">
            <router-view></router-view>
          </v-card>
        </v-col>

      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  name: 'FullScreenCardRight',
}
</script>

<style lang="scss" scoped>
.layout--fullscreen{
  background: url('/img/bg/account-1920.jpg');
  background-size: cover;
}

.form-card {
  padding: 50px;
}
</style>
